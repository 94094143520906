* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#contenedor {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color: none;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #1d1c1c;
  background: #0c0301;
  transition: 0.4s ease-in-out;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.container {
  position: absolute;
  z-index: 990;
  margin: 0 auto;
  width: 98%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 10rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: rgba(245, 245, 245, 0.945);
  span {
    display: block;
  }
  @media (max-width: 1440px) {
    font-size: 5rem;
  }
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #0c0301;
}

#about {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 80%;

  .text-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  h3 {
    font-size: 4rem;
    color: #0c0301;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 100;
  }
  p {
    font-size: 2.1rem;
    color: #0c0301;
    text-align: right;
    max-width: 80%;
    font-weight: 500;
    padding-top: 5px;
    margin: auto;
  }

  @media (max-width: 600px) {
    .text-container {
      flex-direction: column;
    }

    p {
      font-size: 1rem;
      max-width: 100%;
    }
  }
}

.logo-svg {
fill: white;
max-width: 12rem;
opacity: 0.8;

}

.display-none {
  display: none;
}

#about-mobile {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 80%;

  .text-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  h3 {
    font-size: 2rem;
    color: #0c0301;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 100;
  }
  p {
    color: #0c0301;
    text-align: left;
    max-width: 80%;
    font-weight: 500;
    padding-top: 5px;
    margin: auto;
  }

  @media (max-width: 600px) {
    .text-container {
      flex-direction: column;
    }

    p {
      font-size: 1rem;
      max-width: 100%;
    }
  }
}

#projects {
  ul {
    width: 100%;
    z-index: -99999;
  }

  li {
    list-style: none;

    h2 {
      padding: 1rem 4rem;
      font-size: 5rem;
      text-decoration: none !important;
      color: #606063;
      text-align: center;
      font-weight: lighter;
    }
    @media (max-width: 600px) {
      h2 {
        font-size: 3rem;
      }
    }

    li,
    h2:hover {
      transition: all ease-in 0.2s;
      color: #0c0301;
    }
  }
}

#page-mobile {
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: whitesmoke;
  background-color: #0c0301;
  overflow-y: scroll !important;

  #arrow {
    width: 45px;

  }

  .video-container {
    display: flex;
    padding: 1rem 0;

    .video {
      margin: auto;
      height: auto;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    margin-top: 2.5rem;
    max-width: 100%;
  }

  .hero {
    display: flex;
    flex-direction: column;

    .hero-left {
      width: 100%;
      z-index: 10;
      margin: auto;

      .title {
        padding-top: 2rem;
        font-size: 3rem;
        text-align: center;
      }
      .comentario {
        font-size: 1rem;
        padding: 1rem 0;
        text-align: center;
      }
    }

    .hero-image {
      z-index: 0;
      object-fit: cover;
      max-width: 100vw;
    }
  }

  nav {
    display: flex;
    width: 100%;
    position: fixed;
    z-index: 15;
    margin-bottom: 3rem;
    a {
      text-decoration: none;
      color: whitesmoke;
      padding: 1rem;
      height: 100%;
      margin: auto;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .description {
    font-size: 1rem;
    padding: 1rem 0;
  }

  .galeria {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100vw;

    .contenedor-modal {
      padding: 1rem 0;
      margin: auto;
    }

    img {
      max-width: 80vw;
      margin: auto;
    }
  }
}

#page {
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: whitesmoke;
  background-color: #0c0301;
  overflow-y: scroll !important;

  #arrow {
    width: 45px;
  }

  .video-container {
    display: flex;
    padding: 5rem 0;
    .video {
      margin: auto;
    }
  }

  .info {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
  }

  .hero {
    display: flex;
    flex-direction: row;
    padding: auto 5rem;

    .hero-left {
      width: 30%;
      z-index: 10;
      padding: 0 5rem;
      margin: auto;

      .title {
        font-size: 7rem;
        text-align: left;
      }
      .comentario {
        font-size: 2rem;
      }
    }

    .hero-image {
      z-index: 0;
      object-fit: cover;
      width: 70%;
      max-height: 100vh;
    }
  }

  nav {
    display: flex;
    border-right: solid 1px #bdc3c7;
    height: 100%;
    position: fixed;
    z-index: 15;
    a {
      text-decoration: none;
      color: whitesmoke;
      padding: 1rem;
      height: 100%;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .description {
    margin: 5rem;
    font-size: 1.5rem;
  }

  .galeria {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 5rem;
    max-width: 100;

    img {
      max-width: 80vw;
      margin: 1rem;
    }
  }
}

#contacto {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: auto;

  h3 {
    font-size: medium;
    color: #0c0301;
  }

  .contact-containar {
    padding: 0.5rem 0;
  }

  .grey{ 
    padding: 0.2rem;
  }
  #instagram-logo {
    width: 30px;
  }

  footer {
    display: none;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: medium !important;
    }
  }
}
